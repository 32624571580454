import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/node-js-security",
  "date": "2015-11-10",
  "title": "NODE JS SECURITY",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Node.js has gained momentum in the recent years but there are not many security guidelines available out there."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In this article we will go through some of the key facts to keep in mind when you are implementing a Node.js application.`}</p>
    <p>{`The areas of security we will cover are:`}</p>
    <ul>
      <li parentName="ul">{`Authentication`}</li>
      <li parentName="ul">{`Configuration Management`}</li>
      <li parentName="ul">{`CSRF`}</li>
      <li parentName="ul">{`Data Validation`}</li>
      <li parentName="ul">{`Denial of Service`}</li>
      <li parentName="ul">{`Error Handling`}</li>
      <li parentName="ul">{`HTTP Strict Transport Security (HSTS)`}</li>
      <li parentName="ul">{`Node Package Manager (NPM)`}</li>
      <li parentName="ul">{`Secure Transmission`}</li>
      <li parentName="ul">{`Session Management`}</li>
    </ul>
    <h2>{`Authentication`}</h2>
    <h3>{`Brute Force Protection`}</h3>
    <p>{`To throttle invalid login attempts as well as to prevent online cracking and to reduce unnecessary database calls, we could implement a solution similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var failedIPs = {},
  TEN_MINS = 600000,
  THIRTY_MINS = 3 * TEN_MINS;;

function tryToLogin() {
    var failed = failedIPs[remoteIp];
    if (failed && Date.now() < failed.nextAttemptTime) {
        return res.error(); // Throttled. Can't try yet.
    } else {
      // Otherwise do login
    }
}

function onLoginFail() {
    var failed = failedIPs[remoteIp] = failedIPs[remoteIp] ||
      {
        count: 0,
        nextAttemptTime: new Date()
      };

    ++failed.count;
    // Wait another two seconds for every failed attempt
    failed.nextAttemptTime.setTime(Date.now() + 2000 * failed.count);
}

function onLoginSuccess() {
  delete failedIPs[remoteIp];
}

// Clean up people that have given up
setInterval(function() {
    for (var ip in failedIPs) {
        if (Date.now() - failedIPs[ip].nextAttemptTime > TEN_MINS) {
            delete failedIPs[ip];
        }
    }
}, THIRTY_MINS);
`}</code></pre>
    <p>{`Alternativley, you can use `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.npmjs.com/package/express-brute"
        }}>{`express-brute`}</a></strong>{`, a brute-force protection middleware for express routes that rate-limits incoming requests, increasing the delay with each request in a fibonacci-like sequence.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var ExpressBrute = require('express-brute'),

var store = new ExpressBrute.MemoryStore();
var bruteforce = new ExpressBrute(store);

app.post('/auth',
    bruteforce.prevent,
    function (req, res, next) {
        res.send('Success!');
    }
);
`}</code></pre>
    <h2>{`Configuration Management`}</h2>
    <p>{`Your site should set the following HTTP headers:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Content-Security-Policy`}</strong>{` prevents a wide range of attacks, including Cross-site scripting and other cross-site injections`}</li>
      <li parentName="ul"><strong parentName="li">{`Strict-Transport-Security`}</strong>{` enforces secure (HTTP over SSL/TLS) connections to the server`}</li>
      <li parentName="ul"><strong parentName="li">{`X-Content-Type-Options`}</strong>{` prevents browsers from MIME-sniffing a response away from the declared content-type`}</li>
      <li parentName="ul"><strong parentName="li">{`X-Frame-Options`}</strong>{` provides clickjacking protection`}</li>
      <li parentName="ul"><strong parentName="li">{`X-XSS-Protection`}</strong>{` enables the Cross-site scripting (XSS) filter built into most recent web browsers`}</li>
    </ul>
    <Message type="info" title="Clickjacking" content="Clickjacking, also known as a **UI redress attack**, is when an attacker uses multiple transparent or opaque layers to trick a user into clicking on a button or link on another page when they were intending to click on the top level page. Thus, the attacker is **hijacking** clicks meant for their page and routing them to another page, most likely owned by another application, domain, or both." mdxType="Message" />
    <h3>{`Node.js`}</h3>
    <p>{`You can easily set these using `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/helmet"
      }}>{`Helmet`}</a>{` module.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var express = require('express');
var helmet = require('helmet');
var app = express();

app.use(helmet());
`}</code></pre>
    <h3>{`Nginx`}</h3>
    <p>{`In most architectures these headers can be set in web server configuration (Apache, nginx), without changing actual application's code. In nginx it would look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`# nginx.conf

add_header X-Frame-Options SAMEORIGIN;
add_header X-Content-Type-Options nosniff;
add_header X-XSS-Protection "1; mode=block";
add_header Content-Security-Policy "default-src 'self'";
`}</code></pre>
    <Message type="info" title="Check your site for necessary header" content="[http://cyh.herokuapp.com/cyh](http://cyh.herokuapp.com/cyh)" mdxType="Message" />
    <h2>{`Sensitive Data on the Client Side`}</h2>
    <p>{`Make sure when you are deploying applications, you never expose API secrets and credentials in your source code.`}</p>
    <p>{`You can avoid this by regular code reviews, and use of pull requests.`}</p>
    <h2>{`CSRF`}</h2>
    <p>{`Cross-Site Request Forgery (CSRF) is a type of attack that occurs when a malicious Web site, email, blog, instant message, or program causes a user's Web browser to perform an unwanted action on a trusted site for which the user is currently authenticated.`}</p>
    <p>{`We can use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/csrf"
      }}>{`csrf`}</a>{` npm module to create custom CSRF middleware to deal with this kind of attacks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`'use strict';

var cookieParser = require('cookie-parser');
var csrf = require('csurf');
var bodyParser = require('body-parser');
var express = require('express');

// setup route middlewares
var csrfProtection = csrf({ cookie: true });
var parseForm = bodyParser.urlencoded({ extended: false });

var app = express();

// we need this because "cookie" is true in csrfProtection
app.use(cookieParser());

app.get('/form', csrfProtection, function(req, res) {
  // pass the csrfToken to the view
  res.render('send', { csrfToken: req.csrfToken() });
});

app.post('/process', parseForm, csrfProtection, function(req, res) {
  res.send('data is being processed');
});

`}</code></pre>
    <p>{`While on the view layer you have to use the CSRF token like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<form action="/process" method="POST">
  <input type="hidden" name="_csrf" value="{{csrfToken}}">

  Favorite color: <input type="text" name="favoriteColor">
  <button type="submit">Submit</button>
</form>
`}</code></pre>
    <h2>{`Data Validation`}</h2>
    <h3>{`SQL Injection`}</h3>
    <p>{`SQL injection is a `}<strong parentName="p">{`code injection`}</strong>{` technique, used to attack data-driven applications, in which malicious SQL statements are inserted into an entry field for execution (e.g. to dump the database contents to the attacker).`}</p>
    <p>{`Let's look at an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`select title, author from books where id=$id
`}</code></pre>
    <p>{`In this example `}<inlineCode parentName="p">{`$id`}</inlineCode>{` is coming from the user - what if the user enters `}<inlineCode parentName="p">{`2`}</inlineCode>{` or `}<inlineCode parentName="p">{`1=1`}</inlineCode>{`? The query becomes the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`select title, author from books where id=2 or 1=1
`}</code></pre>
    <p>{`The easiest way to defend against these kind of attacks is to use `}<strong parentName="p">{`parameterized queries`}</strong>{` or `}<strong parentName="p">{`prepared statements`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$stmt = $db->prepare("select title, author from books where id=?");
$stmt->bindParam(1, $id);

$id = 1;
$stmt->execute();
`}</code></pre>
    <h2>{`Denial of Service (DoF)`}</h2>
    <p>{`A denial-of-service (DoS) attack is an attempt to make a machine or network resource unavailable to its intended users, such as to temporarily or indefinitely interrupt or suspend services of a host connected to the Internet. This could result in one of the few issues below:`}</p>
    <h3>{`Account Lockout`}</h3>
    <p>{`In an account lockout attack, an attacker attempts to lockout user accounts by purposely failing the authentication process as many times as needed to trigger the account lockout functionality. This, in turn, prevents even the valid user from obtaining access to their account.`}</p>
    <p>{`This can be prevented with the usage of the `}<strong parentName="p">{`rate-limiter`}</strong>{` pattern.`}</p>
    <h3>{`Rate Limiters`}</h3>
    <p>{`For an ideal amount of performance, you can run a light-weight web framework with functions for managing logs on an in-memory database for monitoring and logging the traffic data, be it based on IP or User or Service called by user. The more important choice is the data storage you want to employ.`}</p>
    <p>{`Best and most used free options are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`redis.io`}</strong>{` advanced key-value store`}</li>
      <li parentName="ul"><strong parentName="li">{`ehcache`}</strong>{` standards-based cache, actively developed, maintained and supported as a professional open source project by Terracotta`}</li>
      <li parentName="ul"><strong parentName="li">{`hazelcast`}</strong>{` an open source In-Memory Data Grid for faster execution and seamless elastic scalability`}</li>
      <li parentName="ul"><strong parentName="li">{`VoltDB`}</strong>{` an in-memory operational database`}</li>
    </ul>
    <h3>{`Regular Expression Denial of Service (ReDoS)`}</h3>
    <p>{`The Regular expression Denial of Service (ReDoS) is a Denial of Service attack, that exploits the fact that most Regular Expression implementations may reach extreme situations that cause them to work very slowly (exponentially related to input size). An attacker can then cause a program using a Regular Expression to enter these extreme situations and then hang for a very long time.`}</p>
    <p>{`Examples of `}<strong parentName="p">{`Evil Patterns`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`(a+)+`}</li>
      <li parentName="ul">{`(`}{`[a-zA-Z]`}{`+)*`}</li>
      <li parentName="ul">{`(a|aa)+`}</li>
      <li parentName="ul">{`(a|a?)+`}</li>
      <li parentName="ul">{`(.*a){x} | for x > 10`}</li>
    </ul>
    <p>{`To check your own regular expressions agianst such an attack, you can use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/safe-regex"
      }}>{`safe-regex`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node safe.js '(beep|boop)*' // true
node safe.js '(a+){10}' // false
`}</code></pre>
    <h2>{`Error Handling`}</h2>
    <p>{`Stack traces are not treated as vulnerabilities by themselves, but they often reveal information that can be interesting to an attacker. Providing debugging information as a result of operations that generate errors is considered a bad practice. You should always log them, but do not show them to the users.`}</p>
    <h2>{`HTTP Strict Transport Security (HSTS)`}</h2>
    <p>{`HSTS is an opt-in security enhancement that is specified by a web application through the use of a special response header. Once a supported browser receives this header that browser will prevent any communications from being sent over HTTP to the specified domain and will instead send all communications over HTTPS. It also prevents HTTPS click through prompts on browsers.`}</p>
    <p>{`To test for HSTS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -s -D- https://facebook.com/ | grep -i Strict  
`}</code></pre>
    <h2>{`NPM`}</h2>
    <p>{`NPM packages may contain security vulnerabilities that are critical.`}</p>
    <p>{`You can use `}<strong parentName="p">{`nsp`}</strong>{` or `}<strong parentName="p">{`requireSafe`}</strong>{` to check modules.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm i nsp -g 
nsp audit-package
`}</code></pre>
    <h2>{`Secure Transmission`}</h2>
    <p>{`As HTTP is a clear-text protocol it must be secured via SSL/TLS tunnel, known as HTTPS. Nowadays high-grade ciphers are normally used, misconfiguration in the server can be used to force the use of a weak cipher - or at worst no encryption.`}</p>
    <p>{`You have to test:`}</p>
    <ul>
      <li parentName="ul">{`certificate validity`}</li>
      <li parentName="ul">{`ciphers, keys and renegotiation is properly configured`}</li>
    </ul>
    <p>{`Checking for Certificate information - using `}<strong parentName="p">{`nmap`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nmap --script ssl-cert,ssl-enum-ciphers -p 443,465,993,995 www.example.com 
`}</code></pre>
    <p>{`Testing SSL/TLS vulnerabilities with `}<strong parentName="p">{`sslyze`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`./sslyze.py --regular example.com:3000
`}</code></pre>
    <h2>{`Session Management`}</h2>
    <h3>{`Cookie Flags`}</h3>
    <p>{`The following is a list of the attributes that can be set for each cookie and what they mean:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HttpOnly`}</strong>{` this attribute is used to help prevent attacks such as cross-site scripting, since it does not allow the cookie to be accessed via JavaScript.`}</li>
      <li parentName="ul"><strong parentName="li">{`secure`}</strong>{` this attribute tells the browser to only send the cookie if the request is being sent over HTTPS.`}</li>
    </ul>
    <p>{`Creating a cookie using `}<strong parentName="p">{`cookies`}</strong>{` package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var cookieSession = require('cookie-session');
var express = require('express');

var app = express();

app.use(cookieSession({
  name: 'session',
  keys: [
    process.env.COOKIE_KEY1,
    process.env.COOKIE_KEY2
  ]
}));

app.use(function (req, res, next) {
  var n = req.session.views || 0;
  req.session.views = n++;
  res.end(n + ' views');
});

app.listen(3000);
`}</code></pre>
    <p>{`The code snippets for this post can be found on `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/nodejs-security"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      